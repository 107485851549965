import { gql, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { ListingCategoryType } from '../../components/categories-listing/listing-category.component'
import { Page } from '../../types/page.types'

const categoriesQuery = gql`
  query categories($sort: SortInput, $pagination: PaginationInput, $filters: CategoryFiltersInput) {
    page: pageByUrlKey(urlKey: "categories") {
      metaTitle
      metaDescription
      metaImage
    }
    categories(sort: $sort, pagination: $pagination, filters: $filters) {
      total
      limit
      skip
      data {
        id
        urlKey
        title
        image
      }
    }
  }
`
interface CategoriesQueryProps {
  pageSize?: number
  pageNumber?: number
}
interface CategoriesQueryInterface {
  categories?: ListingCategoryType[]
  metaTitle?: Page['metaTitle']
  metaDescription?: Page['metaDescription']
  metaImage?: Page['metaImage']
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
}

const useCategoriesQuery = ({ pageSize = 8, pageNumber = 1 }: CategoriesQueryProps): CategoriesQueryInterface => {
  const [page, setPage] = useState(pageNumber)
  const [categories, setCategories] = useState<ListingCategoryType[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [total, setTotal] = useState(0)

  const {
    loading: isLoading,
    data: categoriesData,
    fetchMore,
  } = useQuery(categoriesQuery, {
    variables: {
      sort: {
        by: 'dateCreated',
        direction: 'desc',
      },
      filters: {
        isPublished: true,
      },
      pagination: {
        limit: pageSize * page,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (page > pageNumber) {
      fetchMore({
        variables: {
          sort: {
            by: 'dateCreated',
            direction: 'desc',
          },
          filters: {
            isPublished: true,
          },
          pagination: {
            limit: pageSize,
            skip: pageSize * (page - 1),
          },
        },
        updateQuery: (previousData, { fetchMoreResult }) => fetchMoreResult,
      })
    }
  }, [page, pageNumber])

  useEffect(() => {
    if (categoriesData?.categories?.data) {
      setCategories((previousCategories) => [...previousCategories, ...(categoriesData?.categories?.data || [])])
      setTotal(categoriesData?.categories?.total)
    }
  }, [categoriesData?.categories])

  useEffect(() => {
    setHasMore(total > categories.length)
  }, [total, categories])

  const loadMore = useCallback(() => {
    setPage((previousValue) => previousValue + 1)
  }, [])

  const returnedCategories = categories?.length ? categories : categoriesData?.categories?.data

  return {
    categories: returnedCategories || [],
    metaTitle: categoriesData?.page?.metaTitle,
    metaDescription: categoriesData?.page?.metaDescription,
    metaImage: categoriesData?.page?.metaImage,
    isLoading,
    hasMore,
    loadMore,
  }
}

export default useCategoriesQuery

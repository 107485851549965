import React from 'react'
import { Breadcrumbs, CategoriesListing, HelmetMetas } from '../../components'
import { Breadcrumb } from '../../components/breadcrumbs/breadcrumbs.component'
import { useCategoriesQuery } from '../../hooks'

import './categories.styles.css'

const breadcrumbs: Breadcrumb[] = [{ label: 'الرئيسية', link: '/' }]

const CategoriesContainer = (): JSX.Element => {
  const { categories, metaTitle, metaDescription, metaImage, isLoading, hasMore, loadMore } = useCategoriesQuery({
    pageSize: 9,
  })
  return (
    <div className='categories-page page-padding'>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='categories' />

      <div className='categories-page__container centered-container'>
        <h1 className='categories-page__title'>المواضيع</h1>
        <Breadcrumbs
          crumbs={breadcrumbs}
          current={{ label: 'المواضيع' }}
          customClassName='categories-page__breadcrumbs'
        />
        <CategoriesListing
          customContainerClassName='categories-page__listing'
          categories={categories}
          isLoading={isLoading}
          loadMore={loadMore}
          hasMore={hasMore}
          listKey='categories-page'
        />
      </div>
    </div>
  )
}

export default CategoriesContainer
